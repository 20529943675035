import { ApiService } from "./index";

class SsoService {
  providerSync(provider_id, provider) {
    const formdata = new FormData();

    formdata.append("provider_id", provider_id);
    formdata.append("provider", provider);

    return ApiService.post(`/app/v1/sso/assignToken`, formdata).then(
      (data) => data.data
    );
  }

  providerSync1(provider_id, provider, token) {
    const formdata = new FormData();

    formdata.append("provider_id", provider_id);
    formdata.append("provider", provider);
    formdata.append("token", token);

    return ApiService.post(`/app/v1/sso/assignToken`, formdata).then(
      (data) => data.data
    );
  }

  getProviders() {
    return ApiService.get(`/app/v1/sso/getProviders`).then((data) => data.data);
  }

  newGetProviders(apptalkId) {
    return ApiService.get(`/app/v1/sso/newgetproviders/${apptalkId}`).then((data) => data.data);

  }

  getByIdProviderAndProvider(provider_id, provider) {
    const formdata = new FormData();

    formdata.append("provider_id", provider_id);
    formdata.append("provider", provider);

    return ApiService.post(`/app/v1/sso/get`, formdata).then(
      (data) => data.data
    );
  }

  deleteByProviderIdAndProvider(provider_id, provider) {
    const formdata = new FormData();
    formdata.append("provider_id", provider_id);
    formdata.append("provider", provider);

    return ApiService.post(`/app/v1/sso/delete`, formdata).then(
      (data) => data.data
    );
  }
}
export default new SsoService();
