import { ApiService } from "./index";

class DualFAService {
  validate2FACode(id_banco, id_representante, id_usuario, code) {
    const formdata = new FormData();
    formdata.append("id_banco", id_banco);
    formdata.append("id_representante", id_representante);
    formdata.append("id_usuario", id_usuario);
    formdata.append("code", code);

    return ApiService.post(`/web/v1/dualfa/validate`, formdata)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error validating 2FA code:", error);
        throw error;
      });
  }

  getMandatory(id_banco, id_representante) {
    const formdata = new FormData();
    formdata.append("id_banco", id_banco);
    formdata.append("id_representante", id_representante);

    return ApiService.post(`/web/v1/dualfa/getmandatory`, formdata)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting mandatory status:", error);
        throw error;
      });
  }

  getUser2FA(id_banco, id_representante, id_usuario) {
    const formdata = new FormData();
    formdata.append("id_banco", id_banco);
    formdata.append("id_representante", id_representante);
    formdata.append("id_usuario", id_usuario);

    return ApiService.post(`/web/v1/dualfa/getuser`, formdata)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting user 2FA code:", error);
        throw error;
      });
  }

  turnMandatory(id_banco, id_representante, mandatory, changed_by) {
    const formdata = new FormData();
    formdata.append("id_banco", id_banco);
    formdata.append("id_representante", id_representante);
    formdata.append("mandatory", mandatory);
    formdata.append("changed_by", changed_by);

    return ApiService.post(`/web/v1/dualfa/turnmandatory`, formdata)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error turning mandatory:", error);
        throw error;
      });
  }

  setupDualFA(id_banco, id_representante, id_usuario, active, type) {
    const formdata = new FormData();
    formdata.append("id_banco", id_banco);
    formdata.append("id_representante", id_representante);
    formdata.append("id_usuario", id_usuario);
    formdata.append("active", active);
    formdata.append("type", type);

    return ApiService.post(`/web/v1/dualfa/setup`, formdata)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error setting up 2FA:", error);
        throw error;
      });
  }

  validateInternalDual(code, secret) {
    const formdata = new FormData();
    formdata.append("code", code);
    formdata.append("secret", secret);

    return ApiService.post(`/dualfa/internal`, formdata).then((response) => response.data)
  }
}

export default new DualFAService();
