<template>
  <div class="newCenterModalBackdrop">
    <div class="newCenterModalBody">
      <img src="/assets/icons/newIcons/close.svg" class="closeNewCreateTag" @click="closeNewCenterModal" />
      <div class="newCenterModalInner">
        <div class="newCenterModalHeader">
          <div class="newCenterModalIcon">
            <img :src="'data:image/png;base64,' + iconChannel" v-if="
              iconChannel != undefined && iconChannel != '' && iconChannel != null && typeof iconChannel != 'object'
            " class="channelImageNew" />
            <img :src="modalIcon" class="imgTagIcon" v-else />
          </div>

          <div class="newCenterModalTexts">
            <p class="newCenterModalTitle">{{ this.modalTitle }}</p>
            <p class="newCenterModalDesc">{{ this.modalDesc }}</p>
          </div>
        </div>

        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeNewCenterModal() {
      this.$emit("closeCenterModal");
    },
  },
  props: {
    modalTitle: "",
    modalIcon: "",
    modalDesc: "",
    iconChannel: "",
  },
};
</script>

<style>
.newCenterModalDesc {
  opacity: 1;
  color: rgba(35, 35, 39, 0.836589);
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0, 11px;
  text-align: left;
}

.newCenterModalTexts {
  margin-left: 30px;
}

.newCenterModalHeader {
  display: flex;
}

.imgTagIcon {
  width: 58px;
  height: 58px;
}

.newCenterModalIcon {
  opacity: 1;
  background-color: rgba(222, 222, 222, 0.534222);
  width: 85px;
  height: 85px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newCenterModalTitle {
  opacity: 1;
  color: rgba(51, 50, 56, 0.836589);
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0, 21px;
  text-align: left;
  margin-bottom: 6px;
}

.newCenterModalBackdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  overflow: scroll;
  padding-top: 30px;
  padding-bottom: 30px;
}

.channelImageNew {
  border-radius: 50%;
  width: 85px;
  height: 85px;
}

.newCenterModalBody {
  background-color: #fff;
  width: 712px;
  margin: auto;
  border-radius: 4px;
  position: relative;
  padding-top: 50px;
  padding-bottom: 30px;
}

.newCenterModalInner {
  width: 82%;
  margin: auto;
  position: relative;
}

.closeNewCreateTag {
  position: absolute;
  top: 35px;
  right: 35px;
  cursor: pointer;
}
</style>
