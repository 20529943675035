<template>
  <div class="profileModalBackdrop">
    <div class="profileModalContainer">
      <div class="innerEditProfile">
        <!-- TITLE -->
        <p class="editProfileTitle">{{ $t("changeProfile") }}</p>

        <!-- CLOSE MODAL -->
        <img src="/assets/main/closeIcon.svg" @click="hideEditProfile" class="closeModalEdit" />

        <!-- SELECT DISPLAY -->
        <div class="optsRowEdit">
          <div :class="[this.selectedEdit == 1 ? 'optHolderSelect' : 'optHolder']" @click="handleChangeEdit(1)">
            <p class="optText">{{ $t("personalData") }}</p>
          </div>

          <div :class="[this.selectedEdit == 2 ? 'optHolderSelect' : 'optHolder']" @click="handleChangeEdit(2)">
            <p class="optText">{{ $t("sobreMim") }}</p>
          </div>
          <div :class="[this.selectedEdit == 4 ? 'optHolderSelect' : 'optHolder']" @click="handleChangeEdit(4)">
            <p class="optText">{{ $t("integrations") }}</p>
          </div>
          <div :class="[this.selectedEdit == 3 ? 'optHolderSelect' : 'optHolder']" @click="handleChangeEdit(3)"
            v-if="this.adicionais.length != 0">
            <p class="optText">{{ $t("dadosEmpresa") }}</p>
          </div>
        </div>

        <!-- PERGUNTAS OBRIGATÓRIAS -->
        <div v-if="selectedEdit == 1">
          <newCenterModal :modalTitle="$t('2FA.security')" :modalDesc="$t('2FA.security1')"
            :modalIcon="'/assets/icons/newIcons/cards/protection.svg'" :iconChannel="undefined"
            @closeCenterModal="closeCenterModalbyType(1)" v-show="openCenterModals.dualFA.firstpage">

            <!-- SELETOR ENTRE 2FA GOOGLE OU PADRÃO BASEADA NA var: dualFA.selectorType-->
            <div class="optsRowEdit" style="margin-top: 15px; margin-bottom: 24px; justify-content: center">
              <div :class="[this.dualFA.selectorType == 1 ? 'optHolderSelect' : 'optHolder']"
                @click="dualFAselectorMethod(1)">
                <p class="optText">{{ $t("personalData") }}</p>
              </div>

              <div :class="[this.dualFA.selectorType == 2 ? 'optHolderSelect' : 'optHolder']"
                @click="dualFAselectorMethod(2)">
                <p class="optText">{{ $t("apptalk2FA.text1") }}</p>
              </div>
            </div>

            <div v-if="this.dualFA.selectorType == 1">
              <p class="titleModalCenterStep">{{ $t("2FA.steps") }}</p>
              <p class="stepStyle">{{ $t("2FA.steps1") }}</p>
              <img src="/assets/icons/newIcons/2FA/google_auth.png" style="width: 232px; margin-bottom: 6px;">
              <p class="stepStyle">{{ $t("2FA.steps2") }}</p>
              <p class="stepStyle">{{ $t("2FA.steps3") }}</p>
              <p class="stepStyle">{{ $t("2FA.steps4") }}</p>
              <p class="stepStyle">{{ $t("2FA.steps5") }}</p>
              <p class="stepStyle">{{ $t("2FA.steps6") }}</p>
            </div>

            <div v-if="this.dualFA.selectorType == 2">
              <div v-if="this.dualFA.internalStatus">
                <div class="otp-containerlogin" style="margin-top: 20px">
                  <div class="otp-inputs2">
                    <input v-for="(digit, index) in otpDigits2" :key="index" type="text" v-model="otpDigits2[index]"
                      class="otp-inputlogin" maxlength="1" @input="focusNext2(index)"
                      @keydown.backspace="focusPrev2(index)" :ref="'otpInput2' + index">
                  </div>

                  <p class="tokeninfoyellow">{{ $t("apptalk2FA.text2") }} {{ this.dualFA.internalInfo.secret.slice(-3)
                    }}
                  </p>

                </div>
              </div>
              <div v-else>
                <p class="titleModalCenterStepRed">{{ $t("apptalk2FA.text3") }}</p>
                <p class="stepStyle">{{ $t("apptalk2FA.text4") }}</p>
                <img src="/assets/icons/newIcons/2FA/apptalk.png" class="apptalkIconSteps">
                <p class="stepStyle">{{ $t("apptalk2FA.text5") }}</p>
                <p class="stepStyle">{{ $t("apptalk2FA.text6") }}</p>
                <p class="stepStyle">{{ $t("apptalk2FA.text7") }}</p>
                <p class="stepStyle">{{ $t("apptalk2FA.text8") }}</p>
              </div>
            </div>

            <div class="rowBtnsProfile">
              <div class="returnBtn" @click="closeCenterModalbyType(1)">
                <p>{{ $t("back") }}</p>
              </div>

              <div class="saveBtn" @click="generateQR()" v-if="this.dualFA.selectorType == 1">
                <p>{{ $t("2FA.generateQR") }}</p>
              </div>

              <div v-if="this.dualFA.selectorType == 2 && this.dualFA.internalStatus" class="saveBtn"
                @click="setupTypeInternalDual()">
                <p class="optText">{{ $t("2FA.activate") }}</p>
              </div>

              <div v-if="this.dualFA.selectorType == 2 && !this.dualFA.internalStatus" class="saveBtn"
                @click="getDeviceInternalDual()">
                <p class="optText">{{ $t("apptalk2FA.alreadyCode") }}</p>
              </div>
            </div>
          </newCenterModal>

          <!-- MODAL INSERÇÃO DE CODIGO -->
          <newCenterModal :modalTitle="$t('2FA.security')" :modalDesc="$t('2FA.activate')"
            :modalIcon="'/assets/icons/newIcons/cards/protection.svg'" :iconChannel="undefined"
            @closeCenterModal="closeCenterModalbyType(3)" v-show="openCenterModals.dualFA.thirdpage">

            <div class="otp-containerlogin" style="margin-top: 20px">
              <div class="otp-inputs2">
                <input v-for="(digit, index) in otpDigits1" :key="index" type="text" v-model="otpDigits1[index]"
                  class="otp-inputlogin" maxlength="1" @input="focusNext1(index)" @keydown.backspace="focusPrev1(index)"
                  :ref="'otpInput1' + index">
              </div>
            </div>

            <div class="rowBtnsProfile">
              <div class="saveBtn" @click="handleDisable">
                <p>{{ $t("2FA.activate") }}</p>
              </div>
            </div>

          </newCenterModal>


          <!-- MODAL INSERÇÃO DE CODIGO -->
          <newCenterModal :modalTitle="$t('2FA.security')" :modalDesc="$t('2FA.security1')"
            :modalIcon="'/assets/icons/newIcons/cards/protection.svg'" :iconChannel="undefined"
            @closeCenterModal="closeCenterModalbyType(2)" v-show="openCenterModals.dualFA.secondpage">
            <div style="display: flex; margin-top: 36px; margin-bottom: 10px">
              <div>
                <VueQRCodeComponent
                  :text="`otpauth://totp/${this.userInfo.nome_empresa}?secret=${this.dualFA.secret}&issuer=Apptalk`"
                  style="margin-right: 18px;" :size="210">
                </VueQRCodeComponent>
              </div>
              <div style="margin-left: 4px;">
                <p class="dualfacodetext">{{ $t("2FA.code") }} <br>
                  <span class="dualfacode">{{ this.dualFA.secret }}</span>
                </p>
                <div class="otp-container">
                  <p class="dualfacodetext">{{ $t("2FA.code1") }}</p>
                  <div class="otp-inputs">
                    <input v-for="(digit, index) in otpDigits" :key="index" type="text" v-model="otpDigits[index]"
                      class="otp-input" maxlength="1" @input="focusNext(index)" @keydown.backspace="focusPrev(index)"
                      :ref="'otpInput' + index">
                  </div>
                </div>
              </div>
            </div>
            <div class="rowBtnsProfile">
              <div class="returnBtn" @click="closeCenterModalbyType(99)">
                <p>{{ $t("back") }}</p>
              </div>

              <div class="saveBtn" @click="activate2FA()">
                <p>{{ $t("2FA.activate") }}</p>
              </div>
            </div>
          </newCenterModal>

          <!-- USER INFO -->
          <div class="imageHolder">
            <!-- USER IMAGE -->
            <img :src="'data:image/png;base64,' + this.userInfo.profile_picture" class="userImageEdit"
              v-if="this.newUserImage == ''" />

            <img :class="this.newUserImage == '' ? 'noImage' : 'userImageEdit'" id="newUserImageDisplay" />

            <label for="inputNewUserImage">
              <div class="editImageHolder">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                  <g fill="#fff">
                    <path d="M18 8a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z" />
                    <path fill-rule="evenodd"
                      d="M12.057 1.25h-.114c-2.309 0-4.118 0-5.53.19c-1.444.194-2.584.6-3.479 1.494c-.895.895-1.3 2.035-1.494 3.48c-.19 1.411-.19 3.22-.19 5.529v.114c0 2.309 0 4.118.19 5.53c.194 1.444.6 2.584 1.494 3.479c.895.895 2.035 1.3 3.48 1.494c1.411.19 3.22.19 5.529.19h.114c2.309 0 4.118 0 5.53-.19c1.444-.194 2.584-.6 3.479-1.494c.895-.895 1.3-2.035 1.494-3.48c.19-1.411.19-3.22.19-5.529v-.114c0-2.309 0-4.118-.19-5.53c-.194-1.444-.6-2.584-1.494-3.479c-.895-.895-2.035-1.3-3.48-1.494c-1.411-.19-3.22-.19-5.529-.19ZM3.995 3.995c.57-.57 1.34-.897 2.619-1.069c1.3-.174 3.008-.176 5.386-.176s4.086.002 5.386.176c1.279.172 2.05.5 2.62 1.069c.569.57.896 1.34 1.068 2.619c.174 1.3.176 3.008.176 5.386l-.001 1.28l-.222-.03c-2.844-.394-5.447 1.084-6.772 3.313c-1.71-4.325-6.227-7.275-11.274-6.55l-.226.032c.011-1.405.049-2.519.171-3.431c.172-1.279.5-2.05 1.069-2.62Z"
                      clip-rule="evenodd" />
                  </g>
                </svg>
              </div>
            </label>
          </div>

          <input type="file" id="inputNewUserImage" style="display: none" @change="newUserImageEntry"
            accept="image/png, image/jpeg, image/svg" />

          <!-- USER NAME -->
          <p class="mandatoryLabel">{{ $t("profile.name") }}</p>
          <input type="text" class="inputMandatory" v-model="this.userInfo.nome" />

          <p class="mandatoryText" v-if="String(this.userInfo.nome).length == 0">
            * {{ $t("mandatoryField") }}
          </p>

          <!-- USER EMAIL -->
          <p class="mandatoryLabel">{{ $t("profile.email") }}</p>
          <input type="text" class="inputMandatory" v-model="this.userInfo.email" />

          <p class="mandatoryText" v-if="String(this.userInfo.email).length == 0">
            * {{ $t("mandatoryField") }}
          </p>

          <!-- USER DERP -->
          <p class="mandatoryLabel">{{ $t("profile.departamento") }}</p>
          <input type="text" class="inputMandatory" v-model="this.userInfo.setor" />

          <p class="mandatoryText" v-if="String(this.userInfo.setor).length == 0">
            * {{ $t("mandatoryField") }}
          </p>

          <!-- USER ROLE -->
          <p class="mandatoryLabel">{{ $t("profile.cargo") }}</p>
          <input type="text" class="inputMandatory" v-model="this.userInfo.cargo" />

          <p class="mandatoryText" v-if="String(this.userInfo.cargo).length == 0">
            * {{ $t("mandatoryField") }}
          </p>

          <!-- USER BIRTHDAY -->
          <p class="mandatoryLabel">{{ $t("profile.data") }}</p>
          <VueDatePicker v-model="this.userInfo.dt_nascimento" :format="dateFormat" class="datePicker">
          </VueDatePicker>

          <!-- USER 2FA -->
          <p class="mandatoryLabel">{{ $t("2FA.text") }}</p>

          <div class="pseudoInput2FA">
            <div class="containerSwitch">
              <input type="checkbox" class="checkbox" id="checkbox" @change="dualFaChange" v-model="dualFA.status" />

              <label class="switch" for="checkbox">
                <span class="slider"></span>
              </label>

            </div>

            <p class="descriptionInput2FA">{{ $t("2FA.text1") }}</p>

            <img src="/assets/icons/newIcons/2FA/eye.png" class="eye2FA" @click="show2FAcode" v-if="dualFA.status">
          </div>


          <div class="rowBtnsProfile">
            <div class="returnBtn" @click="hideEditProfile">
              <p>{{ $t("back") }}</p>
            </div>

            <div class="saveBtn" @click="updateProfile()" v-if="
              String(this.userInfo.nome).length > 0 &&
              String(this.userInfo.email).length > 0 &&
              String(this.userInfo.setor).length > 0 &&
              String(this.userInfo.cargo).length > 0
            ">
              <p>{{ $t("save") }}</p>
            </div>
          </div>
        </div>

        <!-- SOBRE MIM -->
        <div v-if="selectedEdit == 2">
          <!-- GENERO -->
          <p class="mandatoryLabel">{{ $t("genero") }}</p>
          <select id="genderSelect" v-model="opcionais.genero">
            <option value="" disabled>{{ $t("genero") }}</option>
            <option :value="$t('genders.masc')">
              {{ $t("genders.masc") }}
            </option>
            <option :value="$t('genders.fem')">{{ $t("genders.fem") }}</option>
            <option :value="$t('genders.other')">
              {{ $t("genders.other") }}
            </option>
          </select>

          <p class="mandatoryLabel">{{ $t("biografia") }}</p>
          <!-- BIOGRAFRIA -->
          <textarea cols="30" rows="10" :placeholder="$t('biografia')" class="textAreaMessage"
            v-model="opcionais.bio"></textarea>

          <div class="rowBtnsProfile">
            <div class="returnBtn" @click="hideEditProfile">
              <p>{{ $t("back") }}</p>
            </div>

            <div class="saveBtn" @click="updateOpcionais()">
              <p>{{ $t("save") }}</p>
            </div>
          </div>
        </div>

        <!-- DADOS DA EMPRESA -->
        <div v-if="selectedEdit == 3">
          <div v-for="(question, index) in this.adicionais" :key="index">
            <!-- TEXT -->
            <div v-if="question.question.type == 'text'" class="question">
              <p class="questionLabel">{{ question.question.question }}</p>
              <input type="text" class="inputTextQuestion" v-model="this.adicionaisAnswers[question.id]" />
            </div>

            <!-- DATE -->
            <div v-if="question.question.type == 'date'" class="question">
              <p class="questionLabel">{{ question.question.question }}</p>
              <VueDatePicker :format="dateFormat" class="datePicker" :select-text="$t('select')"
                :cancel-text="$t('close')" v-model="this.adicionaisAnswers[question.id]">
              </VueDatePicker>
            </div>

            <!-- INT -->
            <div v-if="question.question.type == 'int'" class="question">
              <p class="questionLabel">{{ question.question.question }}</p>
              <input type="number" class="inputTextQuestion"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                v-model="this.adicionaisAnswers[question.id]" />
            </div>

            <!-- SLIDER -->
            <div v-if="question.question.type == 'slider'" class="question">
              <p class="questionLabel">{{ question.question.question }}</p>
              <input type="range" class="form-range" id="customRange1" :min="question.question.option[0]"
                :max="question.question.option[1]" v-model="this.adicionaisAnswers[question.id]" />
              <div class="maxEmin">
                <p class="questionLabel">{{ question.question.option[0] }}</p>
                <p class="questionLabel" v-if="this.adicionaisAnswers[question.id] != undefined">
                  {{ $t("value") }} {{ this.adicionaisAnswers[question.id] }}
                </p>
                <p class="questionLabel">{{ question.question.option[1] }}</p>
              </div>
            </div>

            <!-- BOOLEAN -->
            <div v-if="question.question.type == 'boolean'" class="question">
              <p class="questionLabel">{{ question.question.question }}</p>

              <div class="booleanDiv">
                <input type="radio" id="outline" :name="i" :value="true"
                  v-model="this.adicionaisAnswers[question.id]" />
                <p class="radioOpt">{{ $t("true") }}</p>

                <input type="radio" id="outline" :name="i" :value="false" style="margin-left: 12px"
                  v-model="this.adicionaisAnswers[question.id]" />
                <p class="radioOpt">{{ $t("false") }}</p>
              </div>
            </div>

            <!-- RADIO -->
            <div v-if="question.question.type == 'radio'" class="question">
              <p class="questionLabel">{{ question.question.question }}</p>
              <div v-for="(option, j) in question.question.option" :key="j" class="optRadioDiv">
                <input type="radio" :name="i" :value="option" v-model="this.adicionaisAnswers[question.id]" />
                <p class="radioOpt" v-if="option != ''">{{ option }}</p>
                <p class="radioOpt" v-else>" "</p>
              </div>
            </div>

            <!-- MULTI -->
            <div v-if="question.question.type == 'multi'" class="question">
              <p class="questionLabel">{{ question.question.question }}</p>
              <div v-for="(option, k) in question.question.option" :key="k" class="optRadioDiv">
                <label class="containerCheck">
                  <input type="checkbox" :name="`option-${k}`" :value="option"
                    @change="inputCheckboxMulti(question.id, option)"
                    v-model="this.adicionaisAnswers[question.id][k]" />
                  <span class="checkmark"></span>
                </label>
                <p class="radioOpt">{{ option }}</p>
              </div>
            </div>
          </div>
          <div class="rowBtnsProfile">
            <div class="returnBtn" @click="hideEditProfile">
              <p>{{ $t("back") }}</p>
            </div>

            <div class="saveBtn" @click="updateAdicionais()">
              <p>{{ $t("save") }}</p>
            </div>
          </div>
        </div>

        <div v-if="selectedEdit == 4">
          <p class="loginDescClass">{{ $t("loginDesc") }}</p>
          <div v-for="provider in providers" :key="provider.name">
            <div v-if="provider.provider == 'google'" class="providerHolder">
              <div class="leftProvider">
                <img src="/assets/logos/google_logo.png" class="providerLogo" />
                <p class="providerEmail" v-if="!this.providersStatus['google'] || this.providerLoading">
                  {{ $t("sync.no1") }} Google
                </p>
                <!-- <img :src="provider.avatar" class="providerAvatar" v-if="
                  provider.avatar != null &&
                  !this.providerLoading &&
                  this.providersStatus['google']
                " /> -->
                <p class="providerEmail" v-if="!this.providerLoading && this.providersStatus['google']">
                  {{ $t("sync.yes") }} {{ provider.name }}
                </p>
              </div>
              <div class="rightProvider">
                <!-- Se estiver sincronizado -->
                <template v-if="this.providersStatus.google && !this.providerLoading">
                  <button class="undo-button" @click="
                    desyncProvider(provider.provider_id, provider.provider)
                    ">
                    {{ $t("sync.undo") }}
                  </button>
                </template>

                <!-- Se não estiver sincronizado -->
                <template v-if="!this.providersStatus.google && !this.providerLoading">
                  <form @submit.prevent="handleURLSSO('sync', 'google')">
                    <input type="submit" class="submit-button" :value="$t('sync.no')" />
                  </form>
                </template>
              </div>
            </div>

            <div v-if="provider.provider == 'facebook'" class="providerHolder">
              <div class="leftProvider">
                <img src="/assets/logos/facebook_logo.png" class="providerLogo" style="margin-left: 1x;" />
                <p class="providerEmail" v-if="!this.providersStatus['facebook'] || this.providerLoading">
                  {{ $t("sync.no1") }} Facebook
                </p>

                <p class="providerEmail" v-if="!this.providerLoading && this.providersStatus['facebook']">
                  {{ $t("sync.yes") }} {{ provider.name }}
                </p>
              </div>

              <div class="rightProvider">
                <template v-if="this.providersStatus.facebook && !this.providerLoading">
                  <button class="undo-button" @click="
                    desyncProvider(provider.provider_id, provider.provider)
                    ">
                    {{ $t("sync.undo") }}
                  </button>
                </template>

                <template v-if="!this.providersStatus.facebook && !this.providerLoading">
                  <form @submit.prevent="handleURLSSO('sync', 'facebook')">
                    <input type="submit" class="submit-button" :value="$t('sync.no')" />
                  </form>
                </template>
              </div>

            </div>

            <div v-if="provider.provider == 'microsoft'" class="providerHolder">
              <div class="leftProvider">
                <img src="/assets/logos/microsoft_logo.png" class="providerLogo" style="margin-left: 1x;" />
                <p class="providerEmail" v-if="!this.providersStatus['microsoft'] || this.providerLoading">
                  {{ $t("sync.no1") }} Microsoft
                </p>
                <!-- <img :src="provider.avatar" class="providerAvatar" v-if="
                  !this.providerLoading &&
                  this.providersStatus['microsoft']
                " @error="loadNoPfpImg" /> -->
                <p class="providerEmail" v-if="!this.providerLoading && this.providersStatus['microsoft']">
                  {{ $t("sync.yes") }} {{ provider.name }}
                </p>
              </div>

              <div class="rightProvider">
                <!-- Se estiver sincronizado -->
                <template v-if="this.providersStatus.microsoft && !this.providerLoading">
                  <button class="undo-button" @click="
                    desyncProvider(provider.provider_id, provider.provider)
                    ">
                    {{ $t("sync.undo") }}
                  </button>
                </template>

                <!-- Se não estiver sincronizado -->
                <template v-if="!this.providersStatus.microsoft && !this.providerLoading">
                  <form @submit.prevent="handleURLSSO('sync', 'microsoft')">
                    <input type="submit" class="submit-button" :value="$t('sync.no')" />
                  </form>
                </template>
              </div>
            </div>

            <div v-if="provider.provider == 'azure'" class="providerHolder">
              <div class="leftProvider">
                <img src="/assets/logos/azure_logo.png" class="providerLogo" style="margin-left: 1x;" />
                <p class="providerEmail" v-if="!this.providersStatus['azure'] || this.providerLoading">
                  {{ $t("sync.no1") }} Azure
                </p>
                <!-- <img :src="provider.avatar" class="providerAvatar" v-if="
                  !this.providerLoading &&
                  this.providersStatus['microsoft']
                " @error="loadNoPfpImg" /> -->
                <p class="providerEmail" v-if="!this.providerLoading && this.providersStatus['azure']">
                  {{ $t("sync.yes") }} {{ provider.name }}
                </p>
              </div>

              <div class="rightProvider">
                <!-- Se estiver sincronizado -->
                <template v-if="this.providersStatus.azure && !this.providerLoading">
                  <button class="undo-button" @click="
                    desyncProvider(provider.provider_id, provider.provider)
                    ">
                    {{ $t("sync.undo") }}
                  </button>
                </template>

                <!-- Se não estiver sincronizado -->
                <template v-if="!this.providersStatus.azure && !this.providerLoading">
                  <form @submit.prevent="handleURLSSO('sync', 'microsoft')">
                    <input type="submit" class="submit-button" :value="$t('sync.no')" />
                  </form>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// SERVICES
import UserService from "@/services/user.service";
import ssoService from "@/services/sso.service";
import DualfaService from "@/services/dualfa.service";
import dualfaService from "@/services/dualfa.service";
import deviceService from "@/services/device.service";

// COMPONENTS
import newCenterModal from "@/components/newCenterModal.vue";
import VueQRCodeComponent from 'vue-qrcode-component'
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  beforeMount() {
    this.getUserInfo();
    this.getAdicionais();
    this.getOpcionais();
  },

  mounted() {
    this.selectedEdit = this.profileModalSelection;
    this.updateProviders();
  },

  watch: {
    "userInfo.dt_nascimento": function () {
      const newData =
        this.userInfo.dt_nascimento.getFullYear() +
        "-" +
        (this.userInfo.dt_nascimento.getMonth() + 1) +
        "-" +
        this.userInfo.dt_nascimento.getDate();

      this.birthdayDate = newData;

      this.$forceUpdate();
    },
  },

  components: { VueDatePicker, VueQRCodeComponent, newCenterModal },

  props: {
    profileModalSelection: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      userInfo: "",
      selectedEdit: 1,
      dateFormat: "dd/MM/yyyy",
      birthdayDate: "",
      newUserImage: "",

      opcionais: {
        genero: "",
        bio: "",
      },

      adicionais: [],

      adicionaisAnswers: [],

      providers: {
        google: { provider: "google", avatar: null },
        facebook: { provider: "facebook", avatar: null },
        microsoft: { provider: "microsoft", avatar: null },
        azure: { provider: "azure", avatar: null },
      },

      openCenterModals: {
        dualFA: {
          firstpage: false,
          secondpage: false,
          thirdpage: false,
        }
      },

      otpDigits: Array(6).fill(''),
      otpDigits1: Array(6).fill(''),
      otpDigits2: Array(6).fill(''),

      dualFA: {
        status: "",
        secret: "",
        selectorType: "",
        internalStatus: "",
        internalInfo: {
          secret: "",
        },
      },

      providersStatus: [],
      providerLoading: true,
    };
  },

  methods: {
    setupTypeInternalDual() {
      dualfaService.validateInternalDual(this.otpDigits2.join(""), this.dualFA.internalInfo.secret).then(res => {
        // console.log("Avaliação codigo", res)
        if (res.error) {
          toast.error(this.$t("auth.registerCompany.codeError"));

          return;
        }

        DualfaService.setupDualFA(this.userInfo.id_banco, this.userInfo.id_representante, this.userInfo.id, 1, this.dualFA.selectorType).then(response => {
          if (response.success) {
            toast.success(this.$t("2FA.code2"));
            this.dualFA.status = true
            this.openCenterModals.dualFA.firstpage = false;
            this.otpDigits2 = Array(6).fill('');
            this.$forceUpdate();
          } else {
            toast.error(this.$t("2FA.errorSetup"));
            this.otpDigits2 = Array(6).fill('');
            this.$forceUpdate();
          }
        })
      })
    },

    getDeviceInternalDual() {
      deviceService.getMostRecentDeviceWith2FA(this.userInfo.id).then(res => {
        this.dualFA.internalStatus = res.success

        if (res.success) {
          this.dualFA.internalInfo.secret = res.device["2fa"];

          this.$forceUpdate();
          return;
        }

        // toast.error(this.$t("apptalk2FA.error"));
      })
    },

    dualFAselectorMethod(type) {
      this.dualFA.selectorType = type

      if (type == 2) {
        // GET DEVICE
        this.getDeviceInternalDual();
      }
    },

    loadNoPfpImg(e) {
      e.target.src = "/assets/nopfp.jpg";
    },

    handleURLSSO(action, provider) {
      let url;

      url = `https://api.apptalk.com.br/app/v1/sso/web/${action}/${provider}/${this.userInfo.id}/${this.userInfo.id_banco}/${this.userInfo.id_representante}`;

      window.location.href = url;
    },

    handleDisable() {
      if (this.dualFA.selectorType == 1) {
        this.validateCodeToDisable();

        return;
      }

      if (this.dualFA.selectorType == 2) {
        this.validateToDisableInternal();

        return;
      }
    },

    validateToDisableInternal() {
      // console.log("oii", this.otpDigits1.join(""), this.dualFA.internalInfo.secret);


      dualfaService.validateInternalDual(this.otpDigits1.join(""), this.dualFA.internalInfo.secret).then(res => {
        if (res.error) {
          toast.error(this.$t("auth.registerCompany.codeError"));

          return;
        }

        DualfaService.setupDualFA(this.userInfo.id_banco, this.userInfo.id_representante, this.userInfo.id, 0, this.dualFA.selectorType).then(response => {
          if (response.success) {
            toast.success(this.$t("2FA.code2"));
            this.dualFA.status = false
            this.openCenterModals.dualFA.thirdpage = false;
            this.otpDigits1 = Array(6).fill('');
            this.$forceUpdate();
          } else {
            toast.error(this.$t("2FA.errorSetup"));
            this.otpDigits1 = Array(6).fill('');
            this.$forceUpdate();
          }
        })
      })
    },

    validateCodeToDisable() {
      dualfaService.validate2FACode(this.userInfo.id_banco, this.userInfo.id_representante, this.userInfo.id, this.otpDigits1.join("")).then(response => {
        if (response.success) {
          DualfaService.setupDualFA(this.userInfo.id_banco, this.userInfo.id_representante, this.userInfo.id, 0, this.dualFA.selectorType).then(response => {
            if (response.success) {
              toast.success(this.$t("2FA.code2"));
              this.dualFA.status = false
              this.dualFA.secret = response.google_secret;
              this.openCenterModals.dualFA.thirdpage = false;
              this.otpDigits1 = Array(6).fill('');
              this.$forceUpdate();
            } else {
              toast.error(this.$t("2FA.errorSetup"));
              this.otpDigits1 = Array(6).fill('');
            }
          })
          this.getUserInfo();
          this.closeCenterModalbyType(2);
        } else {
          toast.error(this.$t("auth.registerCompany.codeError"));
        }
      })
    },

    activate2FA() {
      dualfaService.validate2FACode(this.userInfo.id_banco, this.userInfo.id_representante, this.userInfo.id, this.otpDigits.join("")).then(response => {
        if (response.success) {

          DualfaService.setupDualFA(this.userInfo.id_banco, this.userInfo.id_representante, this.userInfo.id, 1, this.dualFA.selectorType).then(response => {
            if (response.success) {
              toast.success(this.$t("2FA.code2"));

              this.dualFA.status = true
              this.dualFA.secret = response.google_secret;
              this.openCenterModals.dualFA.secondpage = false;
              this.otpDigits = Array(6).fill('');
              this.$forceUpdate();
            } else {
              toast.error(this.$t("2FA.errorSetup"));
              this.otpDigits = Array(6).fill('');
            }
          })
          this.getUserInfo();
          this.closeCenterModalbyType(2);
        } else {
          toast.error(this.$t("auth.registerCompany.codeError"));
        }
      })
    },


    focusNext(index) {
      if (this.otpDigits[index].length === 1 && index < this.otpDigits.length - 1) {
        this.$refs[`otpInput${index + 1}`][0].focus();
      }
    },

    focusPrev(index) {
      if (this.otpDigits[index] === '' && index > 0) {
        this.$refs[`otpInput${index - 1}`][0].focus();
      }
    },

    focusNext1(index) {
      if (this.otpDigits1[index].length === 1 && index < this.otpDigits1.length - 1) {
        this.$refs[`otpInput1${index + 1}`][0].focus();
      }
    },

    focusPrev1(index) {
      if (this.otpDigits1[index] === '' && index > 0) {
        this.$refs[`otpInput1${index - 1}`][0].focus();
      }
    },

    focusNext2(index) {
      if (this.otpDigits2[index].length === 1 && index < this.otpDigits2.length - 1) {
        this.$refs[`otpInput2${index + 1}`][0].focus();
      }
    },

    focusPrev2(index) {
      if (this.otpDigits2[index] === '' && index > 0) {
        this.$refs[`otpInput2${index - 1}`][0].focus();
      }
    },


    closeCenterModalbyType(type) {
      if (type === 1) {
        this.openCenterModals.dualFA.firstpage = false;

        this.dualFA.status = false;
      }
      if (type === 2) {
        this.openCenterModals.dualFA.secondpage = false;
      }
      if (type === 3) {
        this.openCenterModals.dualFA.thirdpage = false;

        this.dualFA.status = true;
      }

      if (type === 99) {
        this.openCenterModals.dualFA.secondpage = false;
        this.openCenterModals.dualFA.firstpage = true;
      }

      // LIMPA OTPS
      this.otpDigits = Array(6).fill('');
      this.otpDigits1 = Array(6).fill('');
      this.otpDigits2 = Array(6).fill('');

      this.$forceUpdate();
    },

    show2FAcode() {
      this.openCenterModals.dualFA.firstpage = true;
    },

    generateQR() {
      this.openCenterModals.dualFA.firstpage = false;
      this.openCenterModals.dualFA.secondpage = true;

      DualfaService.getUser2FA(this.userInfo.id_banco, this.userInfo.id_representante, this.userInfo.id).then(response => {
        // console.log(response)

        if ((!response.success && response.message == "User Not Found") || response.dualFA == "NÃO") {
          DualfaService.setupDualFA(this.userInfo.id_banco, this.userInfo.id_representante, this.userInfo.id, 0, 1).then(response => {
            if (response.success) {
              this.dualFA.secret = response.google_secret;
              // this.openCenterModals.dualFA.firstpage = true;
            } else {
              toast.error(this.$t("2FA.errorSetup"));
            }
          })
        }
      })
    },

    getUser2FAMethod(userInfo) {
      DualfaService.getUser2FA(userInfo.id_banco, userInfo.id_representante, userInfo.id).then(response => {

        if (response.success) {

          this.dualFA.secret = response.dualFA
          this.dualFA.selectorType = response.type
          this.dualFA.status = response.active == 1 ? true : 0

          this.$forceUpdate()

          return;
        }

        this.dualFA.status = false
        this.dualFA.selectorType = 1;
        this.$forceUpdate()
        // POSSIVEIS RETORNOS
        // 1 - USER FOUND TYPE 1 - SUCCESS TRUE TYPE 1
        // 2 - USER FOUND TYPE 2 - SUCCESS TRUE TYPE 2
        // 3 - USER NOT FOUND - SUCCESS FALSE
      })
    },

    dualFaChange() {
      const dualFAactive = this.dualFA.status == true ? 1 : 0;

      if (dualFAactive) {
        this.openCenterModals.dualFA.firstpage = true;

        return;
      }

      // ABRIR MODAL DE CONFIRMAÇÃO DE REMOVE 2FA
      this.openCenterModals.dualFA.thirdpage = true

      if (this.dualFA.selectorType == 2) {
        this.getDeviceInternalDual();
      }
    },

    updateProviders() {
      this.providerLoading = true;
      ssoService.getProviders().then((res) => {
        if (res.error) {
          // console.error("Erro ao buscar os providers:", res.data);
          var providersStatus = {
            google: false,
            facebook: false,
            microsoft: false,
            azure: false,
          };

          // Garantir que providersStatus é um objeto padrão
          const plainProvidersStatus = JSON.parse(
            JSON.stringify(providersStatus)
          );

          this.providersStatus = plainProvidersStatus;

          this.providers = [
            { provider: "google", avatar: null },
            { provider: "facebook", avatar: null },
            { provider: "microsoft", avatar: null },
            { provider: "azure", avatar: null }
          ];
        } else {
          // Inicializar o objeto com false para todos os provedores conhecidos
          var providersStatus = {
            google: false,
            facebook: false,
            microsoft: false,
            azure: false,
          };

          if (!res.error) {
            const providerList = res.data;

            // Atualizar o objeto para true se o provedor estiver presente na lista recebida
            providerList.forEach((provider) => {
              if (providersStatus.hasOwnProperty(provider.provider)) {
                providersStatus[provider.provider] = true;
              }
            });

            // Garantir que providersStatus é um objeto padrão
            const plainProvidersStatus = JSON.parse(
              JSON.stringify(providersStatus)
            );
            this.providersStatus = plainProvidersStatus;

            // Atualizar o estado com a lista de provedores e o status dos provedores
            const googleProvider = providerList.find(provider => provider.provider === 'google') || { provider: "google", avatar: null };
            const facebookProvider = providerList.find(provider => provider.provider === 'facebook') || { provider: "facebook", avatar: null };
            const microsoftProvider = providerList.find(provider => provider.provider === 'microsoft') || { provider: "microsoft", avatar: null };
            const azureProvider = providerList.find(provider => provider.provider === 'azure') || { provider: "azure", avatar: null };

            this.providers = [googleProvider, facebookProvider, microsoftProvider, azureProvider];

            // console.log(this.providers);

            this.$forceUpdate();
          }
        }
        this.providerLoading = false;
      });
    },

    desyncProvider(provider_id, provider) {
      ssoService
        .deleteByProviderIdAndProvider(provider_id, provider)
        .then((res) => {
          // console.log("KKKKKKKK", res);
          if (!res.error) {
            toast.success(
              this.$t("sync.delete_success", [
                provider[0].toUpperCase() + provider.slice(1),
              ])
            );

            this.providersStatus.provider = false;

            this.$forceUpdate();

            this.updateProviders();
          } else {
            toast.error(
              this.$t("sync.delete_error", [
                provider.toUpperCase() + provider.slice(1),
              ])
            );
          }
        });
    },

    inputCheckboxMulti(questionId, value) {
      // console.log(this.adicionaisAnswers[questionId].includes(value), this.adicionaisAnswers[questionId])

      if (this.adicionaisAnswers[questionId].includes(value)) {
        // console.log("A")

        const index = this.adicionaisAnswers[questionId].indexOf(value);
        this.adicionaisAnswers[questionId].splice(index, 1);
      } else {
        // console.log("B")

        this.adicionaisAnswers[questionId].push(value);
      }

      this.$forceUpdate();
    },

    updateAdicionais() {
      for (let i = 0; i < this.adicionaisAnswers.length; i++) {
        if (
          this.adicionaisAnswers[i] != null &&
          this.adicionaisAnswers[i] != undefined
        ) {
          // console.log("Update adicionais", i, this.adicionaisAnswers[i])

          UserService.createAdicionais(i, this.adicionaisAnswers[i]).then(
            (response) => {
              // console.log("Update adicionais", response)
            }
          );
        }
      }
      // console.log("A")

      this.$emit("updateProfile");
      this.$emit("updateProfile");
      toast.success(this.$t("actions.profileEdit"));
    },

    updateOpcionais() {
      UserService.createOpcionais(
        this.opcionais.bio,
        this.opcionais.genero
      ).then((response) => {
        // console.log(response);
        if (!response.error) {
          this.$emit("updateProfile");
          this.$emit("updateProfile");
          toast.success(this.$t("actions.profileEdit"));
        }
      });
    },

    getAdicionais() {
      UserService.getAdicionais().then((data) => {
        this.adicionais = data.data;

        this.$forceUpdate();

        if (this.adicionais != null) {
          for (let l = 0; l < this.adicionais.length; l++) {
            // console.log("Adicionais log", this.adicionais[l].answers);

            if (this.adicionais[l].answers != null) {
              this.adicionaisAnswers[this.adicionais[l].id] =
                this.adicionais[l].answers.answer;
            }

            if (this.adicionais[l].question.type == "multi") {
              this.adicionaisAnswers[this.adicionais[l].id] = [];

              this.adicionais[l]?.answers?.answer
                .split(",")
                .forEach((element) => {
                  this.adicionaisAnswers[this.adicionais[l].id].push(element);
                });
            }
          }
        }
      });
    },

    getOpcionais() {
      UserService.getOpcionais().then((data) => {
        // console.log("Opcionais", data)
        this.opcionais.bio = data.data.biografia.usuario_biografia;
        this.opcionais.genero = data.data.genero.usuario_status;
      });
    },

    updateProfile() {
      UserService.updateProfileUser(this.userInfo, this.birthdayDate).then(
        (response) => {
          // console.log("ABCD", response);
          if (!response.error) {
            toast.success(this.$t("actions.profileEdit"));
          } else {
            toast.error(this.$t("profileEditError"));
          }
        }
      );

      if (this.newUserImage != "") {
        UserService.changeImageUser(this.newUserImage).then((response) => {
          // console.log("Photo", response.data, this.newUserImage)
        });
      }

      this.$emit("updateProfile");
      this.$emit("updateProfile");
    },

    newUserImageEntry(event) {
      var selectedFile = event.target.files[0];
      var reader = new FileReader();

      this.newUserImage = selectedFile;

      var imgtag = document.getElementById("newUserImageDisplay");

      imgtag.title = selectedFile.name;

      reader.onload = function (event) {
        imgtag.src = event.target.result;
      };

      reader.readAsDataURL(selectedFile);
    },

    // Get user Data
    getUserInfo() {
      UserService.newGetInfo().then((data) => {
        // console.log("data", data)
        this.userInfo = data;

        this.$forceUpdate();

        this.userInfo.dt_nascimento = this.userInfo.dt_nascimento.split("-");

        var novaData = new Date(
          this.userInfo.dt_nascimento[0],
          this.userInfo.dt_nascimento[1] - 1,
          this.userInfo.dt_nascimento[2]
        );

        this.getUser2FAMethod(this.userInfo);
        this.getDeviceInternalDual();

        this.userInfo.dt_nascimento = novaData;

        this.$forceUpdate();
      });
    },

    // Close edit profile modal
    hideEditProfile() {
      this.$emit("closeEditProfile");
    },

    // Change page in edit profile
    handleChangeEdit(index) {
      this.selectedEdit = index;
      if (index == 4) {
        this.updateProviders();
      }
    },


  },
};
</script>

<style>
.apptalkIconSteps {
  transform: rotate(90deg);
  margin-left: 33px;
  margin-top: -28px;
  margin-bottom: -28px;
}

.tokeninfoyellow {
  color: rgba(250, 185, 0, 1);
  font-size: 14px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: -0, 09px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: -12px;
  margin-left: 78px;
}

.stepStyle {
  font-weight: 500;
  color: #9aa1ab;
  margin-bottom: 4px;
  font-size: 14px;
}

.titleModalCenterStep {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 6px
}

.titleModalCenterStepRed {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 6px;
  color: #DA4040
}

.otp-container {
  margin-top: 16px;
}

.otp-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  display: flex;
}

.otp-input {
  width: 55px;
  height: 55px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 24px;
  text-align: center;
  margin: 0 2px;
  transition: border-color 0.3s ease;
}

.otp-inputlogin {
  width: 55px;
  height: 55px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 24px;
  text-align: center;
  margin: 0 10px;
  transition: border-color 0.3s ease;
}

.otp-inputlogin:focus {
  border-color: #4A90E2;
  outline: none;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

.otp-inputs2 {
  display: flex;
  margin: auto;
  justify-content: center;
  margin-top: 20px;
}

.otp-input:focus {
  border-color: #4A90E2;
  outline: none;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

.loginDescClass {
  font-weight: 600;
  color: #979da6;
  font-size: 18px;
  margin-bottom: 18px
}

/* Estilo para o texto sincronizado */
.sync-yes {
  color: green;
  /* Cor verde */
}

/* Estilo para o botão de desfazer */
.undo-button {
  /* Cor de fundo */
  color: #f44336;
  /* Espaçamento interno */
  border: none;
  /* Borda arredondada */
  cursor: pointer;
  /* Cursor em forma de mão */
  font-size: 18px;
  /* Tamanho da fonte */
  font-weight: 500;
}

/* Estilo para o texto não sincronizado */
.sync-no {
  color: red;
  /* Cor vermelha */
}

.submit-button {
  background-color: transparent;
  /* Cor de fundo */
  color: #4caf50;
  /* Espaçamento interno */
  border: none;
  /* Borda arredondada */
  cursor: pointer;
  /* Cursor em forma de mão */
  font-size: 18px;
  /* Tamanho da fonte */
  font-weight: 500;
}

.pseudoInput2FA {
  border-radius: 3px;
  border: 1px solid rgba(195, 195, 195, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 44px;
  width: 100%;
  margin: auto;
  padding: 5px 22px;
  margin-bottom: 0;
  color: rgba(151, 157, 166, 1);
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  display: flex;
  text-align: left;
  align-items: center;
  padding-top: 22px;
  position: relative
}

.eye2FA {
  position: absolute;
  right: 12px;
  top: 5px;
  width: 32px;
  cursor: pointer;
}

.providerEmail {
  margin-bottom: 0;
  margin-left: -14px;
  color: #979da6;
  font-size: 16px;
  font-weight: 500;
}

.providerHolder {
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  padding: 0px 15px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.leftProvider {
  display: flex;
  align-items: center;
}

.providerLogo {
  width: 32px;
  height: 32px;
  margin-right: 30px;
  margin-left: 4px;
}

.providerAvatar {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  margin-right: 24px;
}


.rightProvider {
  display: flex;
  align-items: center;
  border-left: 2px solid #e0e0e0;
  padding-left: 26px;
  height: 35px;
  width: 196px;
}

.rightProvider p {
  font-size: 28px;
  color: #4caf50;
  /* cor do texto sincronizado */
  font-weight: bold;
  /* texto em negrito */
  margin-bottom: 0;
  margin-top: 4px;
  margin-right: 12px;
}

.leftProvider {
  display: flex;
  align-items: center;
  height: 100%;
}

.providerAvatar {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  margin-right: 18px;
}

.question {
  margin-bottom: 16px;
}

.questionLabel {
  margin-bottom: 5px;
  color: rgba(151, 157, 166, 1);
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;

  width: 62ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.questionLabelAnswer {
  margin-bottom: 0px;
  color: rgba(151, 157, 166, 1);
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.inputTextQuestion {
  border-radius: 3px;
  border: 1px solid rgba(195, 195, 195, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  height: 44px;
  padding-left: 20px;
  padding-right: 20px;
}

.opt:hover {
  background-color: var(--yellow);
  color: white !important;
}

.opt p::before {
  color: #979da6;
}

.opt p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.opt {
  cursor: pointer;
  padding-left: 21px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.optsDiv {
  border: 1px solid rgba(195, 195, 195, 1);
  border-top: none;
}

.display {
  border-bottom: none !important;
}

.mainSelect {
  border: 1px solid rgba(195, 195, 195, 1);
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  padding: 13px 21px;
  display: flex;
  cursor: pointer;
}

.mainSelect p {
  margin-bottom: 0;
  color: rgba(151, 157, 166, 1);
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.optIndicator {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  margin: auto;
}

.noDisplay {
  transform: rotate(270deg);
}

#genderSelect {
  border-radius: 3px;
  border: 1px solid rgba(195, 195, 195, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 44px;
  width: 100%;
  margin: auto;
  padding: 5px 22px;
  margin-bottom: 0;

  color: rgba(151, 157, 166, 1);
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.textAreaMessage {
  border-radius: 3px;
  border: 1px solid rgba(195, 195, 195, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 162px;
  width: 100%;
  padding: 20px;
  resize: none;

  color: rgba(151, 157, 166, 1);
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.mandatoryText {
  opacity: 0.699661;
  color: rgba(255, 13, 13, 1);
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0;
  margin-top: 5px;
}

.noImage {
  display: none;
}

.rowBtnsProfile {
  display: flex;
  width: 400px;
  float: right;
  margin-top: 25px;
  justify-content: flex-end;
}

.returnBtn p {
  color: rgba(139, 139, 139, 0.836589);
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0, 11px;
  text-align: center;
  margin-bottom: 0;
}

.returnBtn {
  border-radius: 3px;
  border: 2px solid rgba(211, 211, 211, 1);
  height: 65px;
  padding: 0px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.saveBtn p {
  color: rgba(255, 255, 255, 1);
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0, 11px;
  text-align: center;
  margin-bottom: 0;
}

.saveBtn {
  border-radius: 3px;
  background-color: rgba(250, 185, 0, 1);
  height: 65px;
  padding: 0px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 35px;
}

.optText {
  margin: 0;
}

.optHolderSelect {
  border-radius: 8px;
  background-color: rgba(255, 249, 221, 1);
  color: rgba(250, 185, 0, 1);
  font-size: 18px;
  margin-right: 8px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0, 11px;
  text-align: left;
  cursor: pointer;
  padding: 10px 24px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optHolder {
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
  font-weight: 600;
  margin-right: 8px;
  font-style: normal;
  letter-spacing: -0, 11px;
  text-align: left;
  cursor: pointer;
  padding: 10px 24px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optsRowEdit {
  width: 100%;
  display: flex;
  height: 45px;
  margin-bottom: 20px;
  margin-top: -12px;
}

*:focus {
  outline: none;
}

.AllMandatoryQuestions {
  margin-top: 16px;
}

.mandatoryLabel {
  margin-bottom: 2px;
  color: rgba(151, 157, 166, 1);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 16px;
}

.mandatoryQuestion {
  margin-top: 14px;
  width: 100%;
}

.inputMandatory {
  border-radius: 3px;
  border: 1px solid rgba(195, 195, 195, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 44px;
  width: 100%;
  margin: auto;
  padding: 5px 22px;
  margin-bottom: 0;

  color: rgba(151, 157, 166, 1);
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.editNameIcon {
  cursor: pointer;
  background-color: var(--yellow);
  border-radius: 5px;
  width: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-top: 10px;
}

.imageHolder {
  position: relative;
  text-align: center;
  margin-bottom: -5px;
  width: 200px;
  margin: auto;
}

.editImageHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--yellow);
  position: absolute;
  top: 117px;
  right: 18px;
}

.userInfoTexts {
  margin-left: 28px;
  position: relative;
  width: 100%;
}

.userInfoHolder {
  display: flex;
  margin-top: -20px;
}

.userInfoName {
  color: rgba(51, 50, 56, 0.836589);
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0, 21px;
  text-align: left;
  margin-bottom: 0;
  margin-right: 16px;
  max-width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.userInfoRole {
  color: rgba(51, 50, 56, 0.836589);
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0, 14px;
  text-align: left;
  margin-bottom: 0;
}

.userImageEdit {
  border-radius: 50%;
  width: 164px;
  height: 164px;
  border: 1px solid var(--yellow);
  object-fit: cover;
}

.editProfileTitle {
  color: rgba(51, 50, 56, 0.836589);
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0, 21px;
  text-align: left;
  margin-bottom: 32px;
  margin-left: -3px;
}

.innerEditProfile {
  width: 100%;
  position: relative;
}

.closeModalEdit {
  cursor: pointer;
  position: absolute;
  right: -6px;
  top: 10px;
  width: 32px;
}

.profileModalBackdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
  padding-top: 20px;
  padding-bottom: 20px;
}

.profileModalBackdrop::-webkit-scrollbar {
  display: none;
}

.profileModalContainer {
  background-color: var(--white);
  width: 900px;
  min-height: 565px;
  border-radius: 0.8em;
  padding: 30px 50px;
  margin: auto;
}

button.dp__btn.dp__button {
  display: none !important;
  opacity: 0 !important;
}

input.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg {
  border: none !important;
  border-radius: 3px !important;
  border: 1px solid rgba(195, 195, 195, 1) !important;
  color: rgba(151, 157, 166, 1);
  height: 44px !important;
  font-size: 16px;
  font-weight: 400;
}

/* From Uiverse.io by zanina-yassine */
/* Remove this container when use*/
.component-title {
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: 0;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: #888;
  text-align: center;
}

/* The switch - the box around the slider */
.containerSwitch {
  width: 46px;
  height: 24px;
  position: relative;
  margin-top: -16px;
  margin-left: -8px;
  margin-right: 12px
}

/* Hide default HTML checkbox */
.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.switch {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #e9e9eb;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

/* The slider */
.slider {
  width: 18px;
  height: 18px;
  position: absolute;
  left: calc(50% - 27px/2 - 10px);
  top: calc(50% - 27px/2);
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 4px;
}

.checkbox:checked+.switch {
  background-color: var(--yellow);
}

.checkbox:checked+.switch .slider {
  left: calc(50% - 27px/2 + 10px);
  top: calc(50% - 27px/2);
}


.datePicker {
  border-radius: 3px;
}

.dp__calendar_header {
  display: none !important;
}

svg.dp__icon.dp__clear_icon.dp__input_icons {
  display: none !important;
}

.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: var(--yellow);
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-highlight-color: rgba(25, 118, 210, 0.1);
}

.dp__theme_dark {
  --dp-background-color: #212121;
  --dp-text-color: #ffffff;
  --dp-hover-color: #484848;
  --dp-hover-text-color: #ffffff;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: var(--yellow);
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #a9a9a9;
  --dp-border-color: #2d2d2d;
  --dp-menu-border-color: #2d2d2d;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #737373;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #959595;
  --dp-danger-color: #e53935;
  --dp-highlight-color: rgba(0, 92, 178, 0.2);
}
</style>
