export default {
  "loginErrors": {
    "0010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos faltantes"])},
    "0024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Error! Credenciales no válidas"])},
    "0030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Error! Credenciales no válidas"])},
    "0031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario bloqueado! Inténtalo de nuevo más tarde!"])},
    "0040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código 2FA proporcionado es inválido. Por favor, verifique e intente de nuevo."])},
    "0051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún dispositivo con 2FA o el dispositivo es inválido. Por favor, verifique su configuración."])},
    "0041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código 2FA interno proporcionado es inválido. Intente de nuevo."])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al intentar iniciar sesión. Por favor, intente de nuevo más tarde."])}
  },
  "auth": {
    "registerCompany": {
      "codeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Código inválido!"])}
    }
  },
  "apptalk2FA": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticador Apptalk"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token final: "])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no tienes ningún celular con la aplicación registrada"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Descarga la aplicación:"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Abre la aplicación"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Inicia sesión en tu cuenta"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Ve a perfil"])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Selecciona la opción “Token”"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No hay ningún dispositivo con token registrado!"])},
    "alreadyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya tengo el código"])}
  },
  "2FA": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doble factor de autenticación"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar 2FA para mi acceso"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad de la cuenta"])},
    "security1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configura la autenticación de dos factores"])},
    "steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paso a paso de configuración"])},
    "steps1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Descargue la aplicación:"])},
    "steps2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Abra la aplicación"])},
    "steps3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Seleccione 'Agregar un código'"])},
    "steps4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Seleccione 'Leer código QR'"])},
    "steps5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Permita el acceso a su cámara"])},
    "steps6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Apunte la cámara al código QR generado"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de autenticación:"])},
    "code1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código:"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Código validado con éxito!"])},
    "errorSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo configurar el 2FA"])},
    "generateQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar código QR"])},
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar 2FA"])}
  },
  "2FAmandatory": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticación de dos ‎ factores"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticación de dos factores"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer obligatorio el uso de 2FA para todos los administradores"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumenta la seguridad de tu empresa"])},
    "activated_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Activado por: ", _interpolate(_named("name")), " día ", _interpolate(_named("day"))])}
  },
  "passcode": {
    "errors": {
      "0010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos faltantes"])},
      "0011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El correo electrónico ya está en uso!"])},
      "0012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Error al registrar el usuario!"])}
    }
  },
  "azureErrors": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocurrió un error al crear el usuario."])},
    "validationFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La validación falló. Campos con errores"])},
    "genericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocurrió un error inesperado. Por favor, inténtelo de nuevo."])},
    "alreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario ya registrado en la plataforma"])}
  },
  "error": {
    "bankOrRepRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco o representante es obligatorio"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico inválido"])},
    "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe tener al menos 7 caracteres"])},
    "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas no coinciden"])},
    "unexpectedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocurrió un error inesperado"])},
    "firstNameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El primer nombre es obligatorio"])},
    "lastNameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El apellido es obligatorio"])},
    "departamentoRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El departamento es obligatorio"])},
    "cargoRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cargo es obligatorio"])},
    "passcodeExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su código de acceso ha expirado. Por favor, solicite uno nuevo."])},
    "invalidPasscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de acceso proporcionado es inválido. Verifique e intente de nuevo."])},
    "invalidCelular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de celular es inválido"])},
    "dateRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de nacimiento es obligatoria."])},
    "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de nacimiento proporcionada es inválida."])},
    "dateInFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de nacimiento no puede ser en el futuro."])},
    "ageRestriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario debe tener al menos 14 años."])}
  },
  "tryAgainLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo iniciar sesión. ¡Inténtalo de nuevo!"])},
  "newMarksTxts": {
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar status"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los status le permiten categorizar e identificar rápidamente información importante en cada boleto. Ya sea para resaltar prioridades, realizar un seguimiento del estado o simplemente facilitar la organización"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir marcador"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorrar"])},
    "padrao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcador predeterminado"])},
    "turnPadrao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer por defacto"])},
    "kanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "padraoDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defacto"])},
    "kanbanAtualizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status actualizado con éxito!"])}
  },
  "ssoOptions": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilite su acceso"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una opción a continuación para crear su cuenta"])}
  },
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
  "errorCaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, valide el reCAPTCHA para continuar"])},
  "0070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No tienes permiso para acceder a Apptalk!"])},
  "0072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fue posible crear su cuenta apptalk, ¡póngase en contacto con el administrador!"])},
  "0013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario no encontrado!"])},
  "social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con cuentas sociales"])},
  "social_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta con cuentas sociales"])},
  "sso": {
    "login": {
      "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¡Inicie sesión con ", _interpolate(_list(0)), " exitosamente!"])},
      "successCreation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¡Cuenta creada con ", _interpolate(_list(0)), " exitosamente!"])},
      "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¡Cuenta ", _interpolate(_list(0)), " no encontrada!"])},
      "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede iniciar sesión con la cuenta. Inténtalo de nuevo"])}
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Cuenta sincronizada exitosamente!"])},
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["No se puede sincronizar ", _interpolate(_list(0)), "!"])},
    "error1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Correo electrónico ", _interpolate(_list(0)), " ya en uso"])},
    "error7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Usuario no añadido en la plataforma Azure!"])},
    "error8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Error al obtener un usuario de Azure!"])}
  },
  "sync": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado como"])},
    "no1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace con"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar"])},
    "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "delete_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¡La cuenta ", _interpolate(_list(0)), " se eliminó correctamente!"])},
    "delete_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¡Error al eliminar la cuenta ", _interpolate(_list(0)), "!"])}
  },
  "createNewTicketText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nuevo billete"])},
  "handleMarkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestionar marcadores"])},
  "recusarTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar transferencia"])},
  "aceitarTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar transferencia"])},
  "assumir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomar boleto"])},
  "assumirSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boleto aceptado exitosamente"])},
  "transferDenyNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia rechazada exitosamente!"])},
  "transferNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Solicitud de transferencia completada exitosamente!"])},
  "waitTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamada en curso, espere más actualizaciones"])},
  "waitTransferAdm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu solicitud de transferencia al canal: "])},
  "foiRealizada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" está siendo analizado"])},
  "waitingMod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boleto en espera de moderador"])},
  "waitingAprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferido: "])},
  "transferRequestIndicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En espera de aprobación de transferencia: "])},
  "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo no puede estar vacío"])},
  "handleTicket": {
    "finalizarTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
    "encerrarTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar llamada"])},
    "transferirChamado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
    "transferText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿A qué canal desea transferir la llamada?"])},
    "solicitarApoio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedir soporte"])},
    "apoioText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Para qué canal deseas solicitar soporte?"])},
    "historico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico"])},
    "anotations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoyo"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describa su conclusión"])},
    "successNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota enviada con exito"])},
    "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explicación"])},
    "motivation1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explicación:"])}
  },
  "chamados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" tickets"])},
  "chamado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ticket"])},
  "marcadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actuación"])},
  "avaliar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar"])},
  "arquivar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivar"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa Apptalk en tu dispositivo móvile"])},
  "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalar la aplicación en el dispositivo Android"])},
  "Ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalar la aplicación en el dispositivo ios"])},
  "createNewTicket": {
    "selectAssunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige el tema de la entrada:"])},
    "selectAssunto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar tema:"])},
    "outro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
    "openNewTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir nuevo ticket"])},
    "needSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un tema para continuar"])}
  },
  "createUser": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce el código de invitación recibido"])},
    "completeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa los datos para crear tu cuenta"])},
    "validCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código validado"])}
  },
  "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos personales"])},
  "sobreMim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre mi"])},
  "dadosEmpresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de la empresa"])},
  "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "loginDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifica tu acceso conectando tus redes sociales a nuestro sistema"])},
  "vueTel": {
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de país"])},
    "selecCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona país"])},
    "phonePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej:"])}
  },
  "newCommunication": {
    "describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describa el tema en detalle a continuación, alguien se comunicará con usted en breve y se le notificará"])}
  },
  "tokenTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espera un momento para volver a intentarlo"])},
  "countrys": {
    "AN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antilhas Holandesas"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Bartolomeu"])},
    "BQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonaire, Santo Eustáquio e Saba"])},
    "BV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Bouvet"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Cocos (Keeling)"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Christmas"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Malvinas"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Faroe"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiana Francesa"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Geórgia do Sul e Sandwich do Sul"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "HM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Heard e Ilhas McDonald"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha de Man"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Território Britânico do Oceano Índico"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Cayman"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Martinho"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Marianas do Norte"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinica"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Caledônia"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Norfolk"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Pitcairn"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porto Rico"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunião"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Virgens Britânicas"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Virgens Americanas"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis e Futuna"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territórios Franceses do Sul"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Turks e Caicos"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Martinho (lado holandês)"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard e Jan Mayen"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santa Helena"])},
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afganistán"])},
    "AX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Åland"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argelia"])},
    "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa Americana"])},
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguila"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antártida"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua y Barbuda"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaiyán"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baréin"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladés"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bielorrusia"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bélgica"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belice"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benín"])},
    "BM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermudas"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bután"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia y Herzegovina"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botsuana"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brasil"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunéi"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabo Verde"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camboya"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camerún"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canadá"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Centroafricana"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoras"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo (República Democrática del)"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa de Marfil"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croacia"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chipre"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Checa"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamarca"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yibuti"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["República Dominicana"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egipto"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea Ecuatorial"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiopía"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiyi"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlandia"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francia"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabón"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemania"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grecia"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granada"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haití"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungría"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islandia"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irán"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlanda"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italia"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japón"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordania"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazajistán"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenia"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuwait"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirguistán"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letonia"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líbano"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesoto"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libia"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituania"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxemburgo"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedonia del Norte"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaui"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malasia"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldivas"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malí"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Marshall"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauricio"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["México"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldavia"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mónaco"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marruecos"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Birmania)"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países Bajos"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Zelanda"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níger"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corea del Norte"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noruega"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omán"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistán"])},
    "PW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaos"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panamá"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papúa Nueva Guinea"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perú"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipinas"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polonia"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catar"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumanía"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusia"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruanda"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Cristóbal y Nieves"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santa Lucía"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Vicente y las Granadinas"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santo Tomé y Príncipe"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabia Saudita"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leona"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapur"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eslovaquia"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eslovenia"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islas Salomón"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudáfrica"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corea del Sur"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudán del Sur"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["España"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudán"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suazilandia"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suecia"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suiza"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siria"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwán"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tayikistán"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailandia"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor Oriental"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad y Tobago"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túnez"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquía"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistán"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ucrania"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emiratos Árabes Unidos"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reino Unido"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estados Unidos"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistán"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad del Vaticano"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabue"])}
  },
  "genders": {
    "masc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculino"])},
    "fem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femenina"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])}
  },
  "genero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Género"])},
  "biografia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biografía"])},
  "actions": {
    "notificationDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Notificación eliminada con éxito!"])},
    "notificationsDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Notificaciones eliminadas con éxito!"])},
    "successComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario enviado!"])},
    "successCreatedTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket creado!"])},
    "successFinishTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boleto completado!"])},
    "successPostDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicación eliminada con éxito"])},
    "successPostSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post enviado com sucesso"])},
    "successSendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje enviado!"])},
    "successTransferTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boleto transferido con éxito"])},
    "termoAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Término aceptado con éxito!"])},
    "complaintCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclamación realizada con éxito!"])},
    "complaintNonCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede informar reclamación!"])},
    "finishComplaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe eliminado con éxito"])},
    "changeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado actualizado con éxito"])},
    "profileEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Perfil editado con éxito!"])},
    "sucessToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Token enviado con éxito!"])},
    "successToken1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Token verificado con éxito!"])},
    "successPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Contraseña alterada con éxito!"])},
    "sucessParecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Opinión añadida con éxito!"])},
    "successAvaliar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Servicio evaluado con éxito!"])},
    "successArquivar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ticket archivado con éxito!"])}
  },
  "noPossiblePass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No se puede actualizar la contraseña!"])},
  "verificando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token de verificación..."])},
  "typeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe tu mensaje..."])},
  "noFeedOnEditorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este editorial no tiene publicaciones"])},
  "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token no valido!"])},
  "profileEditError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede actualizar tu perfil"])},
  "noParecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se agregaron comentarios a este informe"])},
  "parecer": {
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusão"])},
    "noConclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta denuncia se finalizó sin dictamen"])}
  },
  "userInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Usuario registrado con éxito!"])},
  "userInviteFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No se puede registrar el usuario!"])},
  "noFeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, pero no tienes acceso a ninguna publicación"])},
  "noFeed1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, póngase en contacto con el administrador"])},
  "communicationTexts": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
    "finish1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encerrar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retornar ticket"])},
    "wantToFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres finalizar ticket?"])}
  },
  "profile": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "sobrenome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "departamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departamento"])},
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])}
  },
  "adminPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel de administrador"])},
  "apptalk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apptalk ® 2024"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorrar"])},
  "mandatoryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obrigatório"])},
  "changeCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar de empresa"])},
  "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy satisfecho!"])},
  "minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No satisfecho"])},
  "noPossibleAvaliar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede calificar!"])},
  "changeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentario"])},
  "userdoNotExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario no encontrado en la plataforma!"])},
  "sendingToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviando token..."])},
  "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentarios"])},
  "commentsUpper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
  "commentVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentar"])},
  "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de comunicación"])},
  "createPostPlaceHldr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En que estas pensando, "])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" día"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" dias"])},
  "denounceVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delatar post"])},
  "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
  "entryManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar como administrador"])},
  "entryUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar como usuario"])},
  "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falso"])},
  "firstAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tengo un código"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
  "videoOmbuds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Bienvenidos a la etapa de sensibilización!"])},
  "videoOmbuds1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antes de continuar con su informe, mire estos videos informativos para comprender mejor el contexto y los problemas relacionados con el asunto"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste la contraseña?"])},
  "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galería"])},
  "goHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la página principal"])},
  "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página de inicio"])},
  "likeVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
  "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Error! Credenciales no válidas"])},
  "loginText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apptalk ayuda a su empresa a conectarse con sus empleados de una forma sencilla e innovadora."])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mes"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" meses"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "noFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, parece que no se encontró la página que está buscando. Esto podría deberse a que se perdió la dirección de la página o a que la página se eliminó."])},
  "noTicketsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han creado tickets en este canal de comunicación"])},
  "notifications": {
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Felicita a los cumpleañeros del día!"])},
    "birthdayReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Tienes nuevos mensajes de felicitación!"])},
    "noNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay nuevas notificaciones en este momento 📭"])}
  },
  "ombudsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvidoria"])},
  "ombudsmanTexts": {
    "messageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reportar lo que paso"])},
    "acceptConduta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto el código de conducta"])},
    "acceptTermo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy de acuerdo con el término de privacidad"])},
    "adm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "conduta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de Conducta"])},
    "errorAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes aceptar el término para continuar"])},
    "firstMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensajes invisibles"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige el canal al que quieres unirte"])},
    "identify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres identificarte?"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "next1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "noOmbuds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su empresa no tiene disponible un canal de defensoría"])},
    "resposta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resposta"])},
    "secondMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensajes en revisión"])},
    "termo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termo de privacidad"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["También puede seleccionar el archivo"])},
    "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Haciendo clic aquí!"])},
    "arquivados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivados"])},
    "ativos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos"])},
    "sendAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado em: "])}
  },
  "openTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets abiertos"])},
  "openArquivados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets archivados"])},
  "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar contraseña"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gravar video"])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
  "principalPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina principal"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
  "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver menos"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
  "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdadero"])},
  "typeComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribir un comentario..."])},
  "userPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email o Teléfono"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor: "])},
  "videoNoSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, su navegador no es compatible con el reproductor de video"])},
  "warnings": {
    "noContentPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el contenido de la publicación"])},
    "uploadingPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subiendo la publicación"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperar el final de la carga"])}
  },
  "changePass": {
    "cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "confirmId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme su identidad"])},
    "findAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentra tu cuenta"])},
    "insertCell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu teléfono para cambiar tu contraseña"])},
    "insertCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce el código que te enviamos"])},
    "newPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una nueva contraseña"])},
    "req1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 1 carácter especial"])},
    "req2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 1 carácter en mayúscula"])},
    "req3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 1 carácter en minúscula"])},
    "req4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 1 carácter numérico"])},
    "sucessId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad confirmada con éxito"])},
    "empresas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compañías"])},
    "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la empresa a la que quieres iniciar sesión"])}
  },
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" semana"])},
  "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" semanas"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" año"])},
  "loadingChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando"])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" años"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayer"])},
  "AdmOmbudsOpts": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
    "denounce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queja"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describa lo que pasó:"])},
    "responseLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describa su conclusión:"])},
    "noArch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta denuncia no tiene anexos."])}
  },
  "colab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colaborador"])}
}