import { ApiService } from "./index";

class DeviceService {
  // Define o dispositivo como principal
  setPrimaryDevice(deviceId, id_usuario) {
    const formdata = new FormData();
    formdata.append("device_id", deviceId);
    formdata.append("id_usuario", id_usuario);

    return ApiService.post(`/app/v2/user/device/primary`, formdata).then((response) => response.data)
  }

  // Obtém o dispositivo mais recente com 2FA configurado para o usuário
  getMostRecentDeviceWith2FA(id_usuario) {
    const formdata = new FormData();
    formdata.append("usuario_id", id_usuario);

    return ApiService.post(`/app/v2/user/device/recent-with-2fa`, formdata).then((response) => response.data)
  }

  // Obtém detalhes de 2FA para um dispositivo específico pelo ID
  getDevice2FA(deviceId) {
    return ApiService.get(`/app/v2/user/device/${deviceId}/2fa`).then((response) => response.data)
  }
}

export default new DeviceService();
