import { ApiService } from "./index";
class AuthService {
  login(data) {
    if (data.type == "email") {
      return ApiService.post("/app/v5/auth/user/login", {
        email: data.userinfo,
        password: data.password,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
    } else {
      return ApiService.post("/app/v5/auth/user/login", {
        numero_celular: `55${data.userinfo}`,
        password: data.password,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
    }
  }

  newlogin(data) {
    // console.log('data', data);

    // Prepare the payload
    const payload = {
      password: data.password,
    };

    // Determine if the user is logging in via email or cellphone
    if (data.type === "email") {
      payload.email = data.userinfo;
    } else {
      payload.numero_celular = `55${data.userinfo}`;
    }

    // Include the 2FA code if provided
    if (data.code) {
      payload.code = data.code;
    }

    // Send the login request
    return ApiService.post("/app/v5/auth/user/newlogin", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }


  changeCompany(userId) {
    return ApiService.get(`/app/v3/auth/select/${userId}`).then(
      (response) => response.data
    );
  }
}
export default new AuthService();
